import {
  Box,
  Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";
import VerticalProject from "../Layouts/VerticalProject";
import HorizontalProject from "../Layouts/HorizontalProject";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import '../../styles/swiper-bullet.css';

const projects = [
  {
    name: 'StarPost',
    imgUrl: 'https://github.com/kaipeps/space_traders_starpost/raw/main/planning/screenshots/ship_menu.png',
    about: 'A user-friendly app to interact with the SpaceTraders API! Register an Agent, take on contracts and manage your fleet.',
    repo: 'https://github.com/kaipeps/space_traders_starpost',
    demo: 'https://space-traders-starpost.surge.sh/'
  },
  {
    name: 'Focus.',
    imgUrl: 'https://github.com/kaipeps/project_FOCUS/raw/main/note/images/home_screenshot.png',
    about: 'A web app to help people with ADHD sort out their tasks in asimple format... with a few extra features to scratch that dopamine itch!',
    repo: 'https://github.com/kaipeps/project_FOCUS',
    demo: 'https://project-focus.onrender.com/'
  },
  {
    name: 'Generator Against Humanity',
    imgUrl: 'https://github.com/kaipeps/generator_against_humanity/raw/main/static/images/readme/landing.png',
    about: 'A web app based on a party game for horrible people. Generate your own Cards Against Humanity, share them and use the generator to make random card combos!',
    repo: 'https://github.com/kaipeps/generator_against_humanity',
    demo: 'https://generator-against-humanity.onrender.com'
  },
  {
    name: 'Tic Tac Toe',
    imgUrl: 'https://github.com/kaipeps/tic_tac_toe/raw/main/image_assets/final_screenshot.png',
    about: 'My first project for the SEI course; A basic tic tac toe game using simple HTML, CSS & JavaScript.',
    repo: 'https://github.com/kaipeps/tic_tac_toe',
    demo: 'https://kaipeps.github.io/tic_tac_toe/'
  },
];

export default function Projects() {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Typography variant="h4" textAlign='center' padding='0.2em 0'>
        My projects
      </Typography>
      <Typography variant="body1" textAlign='center' >Swipe to explore</Typography>
      <Box maxWidth={`${smScreen ? 20 : mdScreen ? 32 : 42}em`}>
        <Swiper
          slidesPerView={1}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        >
          {projects.map((project, idx) =>
            <SwiperSlide key={idx}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.2em',
                alignItems: 'center',
                padding: '0.2em',
                marginBottom: '2em'
              }}>
                {mdScreen ? <VerticalProject project={project} /> : <HorizontalProject project={project} />}
              </Box>
            </SwiperSlide>
          )}
        </Swiper>
      </Box >
    </>
  )
};