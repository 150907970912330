import {
  Box,
  Link,
  Typography,
} from '@mui/material';

export default function Home() {
  return (
    <>
      <Typography variant="h4" textAlign='center' padding='0.2em 0'>
        Hey there!
      </Typography>
      <Box alignSelf="center">
        <iframe
          src={`https://www.youtube.com/embed/CipG10yD5hI`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </Box>
      <Typography variant="h5" textAlign='center' >
        I’m Kai.
      </Typography>
      <Typography variant="h6" color='#FBB434'>
        I'm a non-binary, neurodivergent Software Engineer making the switch from hospitality into technology.
      </Typography>
      <Typography variant="body1" >
        Highly adaptable and ready to learn, with strong interpersonal skills I’ve developed through several years of customer service experience, I look forward to joining a company where I can put my new skills to the test. I would love to continue building my skills while raising awareness for my communities and helping connect other queer & neurodivergent people to the world around us! If you’d like to know some more about me, have a read <Link href='/about' color='#FBB434' >here</Link>!
      </Typography>
    </>
  );
};