import {
  Grid,
  Link,
  Typography,
} from '@mui/material';

export default function Contact() {
  const contacts = [
    {
      source: 'Phone:',
      link: 'tel:61449994286',
      text: '+61 449 994 286'
    },
    {
      source: 'Email:',
      link: 'mailto:kaipepper99@gmail.com',
      text: 'kaipepper.dev@gmail.com'
    },
    {
      source: 'LinkedIn:',
      link: 'https://linkedin.com/in/kai-pepper',
      text: 'linkedin.com/in/kai-pepper'
    },
    {
      source: 'GitHub:',
      link: 'https://github.com/kaipeps',
      text: 'github.com/kaipeps'
    },
  ]

  return (
    <>
      <Typography variant="h4" textAlign='center' padding='0.2em 0' >
        Let's get in touch!
      </Typography>
      <Typography variant="body1" textAlign='center' >
        If I sound like the kind of person you want on your team, come and have a chat! Let's see what we can do together.
      </Typography>
      <Grid container spacing={1}>
        {contacts.map(({ source, link, text }, idx) =>
          <Grid item key={idx} xs={12} sm={6}>
            <Typography variant="body1" textAlign='center' >
              {source}
            </Typography>
            <Typography variant="body1" textAlign='center' >
              <Link href={link} color='#FBB434' >{text}</Link>
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};