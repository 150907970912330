import {
  Grid,
  Link,
  Typography
} from "@mui/material";

export default function HorizontalProject({ project }) {
  let {
    name,
    imgUrl,
    about,
    repo,
    demo
  } = project

  return (
    <Grid container spacing={0}>
      <Grid item xs={7} sx={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <img src={imgUrl} alt={name} style={{ maxHeight: '190px', objectFit: 'contain' }} />
      </Grid>
      <Grid item xs={5}>
        <Typography variant="h6" textAlign="center">{name}</Typography>
        <Typography variant="body1" padding='0 0.4em'>
          {about}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="body1" textAlign='center' >
              <Link href={repo} color='#FBB434' >Repository</Link>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" textAlign='center' >
              <Link href={demo} color='#FBB434' >Demo Site</Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};