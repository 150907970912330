import {
  Typography,
} from '@mui/material';

export default function About() {
  return (
    <>
      <Typography variant="h4" textAlign='center' padding='0.2em 0' >
        A bit about me...
      </Typography>
      <Typography variant="body1">
        I'm an avid gamer, AuDHD hobbyist, and most importantly an aspiring Software Engineer who decided to make a change after spending the last few years working in hospitality.
      </Typography>
      <Typography variant="body1">
        I was diagnosed with Autism & ADHD in late 2021 and I currently identify as non-binary! (they/them pronouns)
      </Typography>
      <Typography variant="h5" textAlign='center' color='#FBB434'>
        My tech background
      </Typography>
      <Typography variant="body1">
        I studied some programming in school, but I fell away from it post-grad. I recently had the chance to come back to it through General Assembly's Software Engineering Immersive course, where I found I not only had a talent for solving problems with code but really enjoyed it!
      </Typography>
      <Typography variant="body1">
        In the space of three months I levelled up my years-old knowledge of basic coding to fluency in Front-End development with both standard HTML/CSS/JS & React.js. I also had the chance to explore Back-End Development using Flask and Express.js as well!
      </Typography>
      <Typography variant="body1">
        I learned how to solve coding problems on the fly, plan out & manage my own projects, and collaborate on projects using tools like GitHub, Notion & Trello!
      </Typography>
      <Typography variant="h5" textAlign='center' color='#FBB434'>
        Why do I do this?
      </Typography>
      <Typography variant="body1">
        As a queer neurodivergent person in this day and age, I find myself in two communities that are often misunderstood by those around us. There are so many people just like me who are overlooked because of things they can’t control and their talents end up going to waste.
      </Typography>
      <Typography variant="body1">
        I want to use my talents to help fix that. I would love to work on projects that increase awareness for my communities and create more opportunities to my peers!
      </Typography>
    </>
  );
};