import {
  Box,
  Container,
  useTheme,
  useMediaQuery
} from "@mui/material";
import NavBar from "./NavBar";
import { alpha, styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5em',
  maxWidth: '45em',
  margin: '0 auto',
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: `0px 0px 3px ${theme.palette.primary.main},
    0px 0px 3px ${theme.palette.primary.main} inset`,
  borderRadius: '0.3em',
  padding: '0.8em 1.5em',
  color: theme.palette.secondary.contrastText,
}));

export default function DefaultLayout({ children }) {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const lgScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box sx={{
      background: 'url(https://images.unsplash.com/photo-1529502669403-c073b74fcefb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh',
      maxHeight: '100vh',
      overflowY: 'auto'
    }}>
      <NavBar />
      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: smScreen ?
          '1em' :
          lgScreen ?
            '2em' :
            '3em',
      }}>
        <StyledBox sx={{
          background: alpha(theme.palette.secondary.dark,
            smScreen ? 0.9 : 0.95),
        }}>
          {children}
        </StyledBox>
      </Container>
    </Box>
  );
};