import { Routes, Route } from 'react-router-dom';
import MyThemeProvider from './Theme';
import DefaultLayout from './components/Layouts/DefaultLayout';
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Contact from './components/Pages/Contact';
import Projects from './components/Pages/Projects';

export default function App() {
  const pages = [
    { path: '/', element: <Home /> },
    { path: '/about', element: <About /> },
    { path: '/contact', element: <Contact /> },
    { path: '/projects', element: <Projects /> },
  ];

  return (
    <MyThemeProvider>
      <DefaultLayout>
        <Routes>
          {pages.map(({ path, element }, idx) => <Route key={idx} path={path} element={element} />)}
        </Routes>
      </DefaultLayout>
    </MyThemeProvider>
  );
};