import {
  Grid,
  Link,
  Typography
} from "@mui/material";

export default function VerticalProject({ project }) {
  let {
    name,
    imgUrl,
    about,
    repo,
    demo
  } = project

  return (
    <>
      <Typography variant="h6" textAlign="center">{name}</Typography>
      <img src={imgUrl} alt={name} style={{ maxWidth: '95%', maxHeight: '220px', objectFit: 'contain' }} />
      <Typography variant="body1" padding='0 0.4em'>
        {about}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="body1" textAlign='center' >
            <Link href={repo} color='#FBB434' >Repository</Link>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" textAlign='center' >
            <Link href={demo} color='#FBB434' >Demo Site</Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};