import {
  AppBar,
  Box,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';

export default function NavBar() {
  const pages = [
    { page: 'About', path: '/about' },
    { page: 'Projects', path: '/projects' },
    { page: 'Contact', path: '/contact' },
  ]

  return (
    <Box>
      <AppBar>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Typography variant="h5">
            <Link href='/'
              color='#fff'
              sx={{
                textAlign: 'center'
              }}
            >
              Kai Pepper
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {pages.map(({ page, path }, idx) =>
            <Link key={idx} href={path}
              color='#fff'
              sx={{
                padding: '0.5em'
              }}>{page}</Link>)}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
};